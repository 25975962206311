import React from "react";
import { Box, Typography, Button } from "@mui/material";
import HeroBannerImage from "../assets/images/banner2.jpeg";

const HeroBanner = () => {
	return (
		<Box
			sx={{
				mt: { lg: "212px", xs: "70px" },
				ml: { sm: "50px" },
			}}
			position="relative"
			p="20px"
		>
			<Typography color="#FF2625" fontWeight="600" fontSize="26px">
				Ftiness Club
			</Typography>
			<Typography
				fontWeight={700}
				sx={{
					fontSize: { lg: "44px", xs: "40px" },
				}}
				mb="23px"
				mt="30px"
			>
				Sweat, smile <br /> and Repeat
			</Typography>
			<Typography fontSize="22px" lineHeight="35px" mb={4}>
				Checkout the most efective exercises
			</Typography>
			<Button
				variant="contained"
				color="error"
				href="#exercises"
				sx={{
					backgroundColor: "#ff2625",
					padding: "10px",
				}}
			>
				Explore Exercises
			</Button>
			<Typography
				fontWeight={600}
				color="ff2625"
				sx={{
					opacity: 0.1,
					display: { lg: "block", xs: "none" },
				}}
				fontSize="200px"
			>
				Exercise
			</Typography>
			<img
				src={HeroBannerImage}
				alt="HeroBannerImage"
				className="hero-banner-img"
				style={{ borderRadius: "50px 0 0 50px" }}
			/>
		</Box>
	);
};

export default HeroBanner;
